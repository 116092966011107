@import "./_inc.scss";

div.saving {
    display: flex;
    flex-direction: row;

    justify-content: center;

    height: calc(var(--defaultScale)*580px);

    >div {
        display: flex;
        flex-direction: column;
        width: calc(var(--defaultScale)*192px);
        height: calc(var(--defaultScale)*320px);
        background-size: calc(var(--defaultScale)*192px) calc(var(--defaultScale)*320px);
        align-items: center;
        justify-items: center;


        >div.img {
            flex-shrink: 1;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;


        }

        * {
            font-size: calc(var(--defaultScale)*23px);
            color: black;
            font-weight: bold;
        }

        position: relative;
        margin-right: calc(var(--defaultScale)*18px);

        &:nth-child(1) {
            top: calc(var(--defaultScale)*134px);
            background-image: url(../assets/images/savings/pottery.png);
        }

        &:nth-child(2) {
            top: calc(var(--defaultScale)*114px);
            background-image: url(../assets/images/savings/jade.png);
        }

        &:nth-child(3) {
            top: calc(var(--defaultScale)*138px);
            background-image: url(../assets/images/savings/cu.png);
        }

        &:nth-child(4) {
            top: calc(var(--defaultScale)*110px);
            background-image: url(../assets/images/savings/fe.png);
        }

        &:nth-child(5) {
            top: calc(var(--defaultScale)*159px);
            background-image: url(../assets/images/savings/paint.png);
        }

        &:nth-child(6) {
            top: calc(var(--defaultScale)*154px);
            background-image: url(../assets/images/savings/china.png);
        }

    }

}

section.savinginfo {
    >nav {
        div {
            a {
                padding: 0 calc(var(--defaultScale)*60px);
            }
        }
    }

    >div.savingitems {
        margin: calc(var(--defaultScale)*58px) calc(var(--defaultScale)*371px);
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: calc(var(--defaultScale)*39px);
        grid-column-gap: calc(var(--defaultScale)*25);
        grid-template-rows: repeat(5, 1fr);

        >div {
            width: calc(var(--defaultScale)*210px);
            padding: calc(var(--defaultScale)*12px) calc(var(--defaultScale)*12px);

            >img {
                width: 100%;
                height: 100%;
            }

            >div {
                display: flex;
                font-size: calc(var(--defaultScale)*14px);
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

section.savingdetial.bg {
    padding: calc(var(--defaultScale)*134px) calc(var(--defaultScale)*415px);
    display: flex;
    flex-direction: row;

    label {
        width: calc(var(--defaultScale)*80px);
        padding-right: calc(var(--defaultScale)*20px);
        display: inline-block;
        text-align-last: justify;
        font-weight: 600;
    }

    img {
        width: calc(var(--defaultScale)*400px);
        ;
    }

    * {
        color: black;
        font-size: calc(var(--defaultScale)*19px);
    }

    ;

    >div.name {
        font-size: calc(var(--defaultScale)*30px);
        font-weight: 600;
    }

    >div.content {
        margin-left: calc(var(--defaultScale)*117px);

        >div.name {
            font-size: calc(var(--defaultScale)*34px);

            * {
                font-size: calc(var(--defaultScale)*34px);
            }

            margin-bottom: calc(var(--defaultScale)*20px);

        }

        >div.time {
            margin-top: calc(var(--defaultScale)*48px);
            margin-bottom: calc(var(--defaultScale)*39px);
        }

        >div.text {
            font-size: calc(var(--defaultScale)*17px);
            margin-bottom: calc(var(--defaultScale)*20px);
            line-height: calc(var(--defaultScale)*31px);
            margin-top: calc(var(--defaultScale)*72px);

        }
    }
}