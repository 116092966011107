@import "_inc.scss";

div.base {
    margin-left: calc(var(--defaultScale)*100px);
    margin-right: calc(var(--defaultScale)*100px);

    >article:nth-child(2n+1) {
        div.content {
            flex-direction: row;
        }
    }

    >article:nth-child(2n) {
        div.content {
            flex-direction: row-reverse;
        }
    }

    >article.infos {
        margin-bottom: calc(var(--defaultScale)*42px);

    }

    div.content {
        display: flex;

        margin: 0 calc(var(--defaultScale)*30px);

        >div.img {

            flex-shrink: 0;
            width: calc(var(--defaultScale)*483px);
            height: calc(var(--defaultScale)*265px);
        }

        >div.space {
            width: calc(var(--defaultScale)*25px);
            flex-shrink: 0;
        }

        >div.detial {
            clear: both;

            >div.btn {
                float: right;
                background-color: var(--lineColor);
                color: white;
                text-align: center;
                line-height: calc(var(--defaultScale)*40px);
                border-radius: 10px;
                width: calc(var(--defaultScale)*100px);
                height: calc(var(--defaultScale)*40px);

                &:hover {
                    cursor: pointer;
                }

            }

            >p {
                &.name {
                    margin-top: 0;
                    font-size: calc(var(--defaultScale)*24px);
                    font-weight: 200%;
                    margin-bottom: calc(var(--defaultScale)*10px);
                }

                &.showinfo {
                    font-size: calc(var(--defaultScale)*18px);
                    margin: calc(var(--defaultScale)*6px) 0 0 0;
                    line-height: calc(var(--defaultScale)*18px);
                }

                &.desc {
                    text-indent: 2em;
                }
            }
        }
    }

    div.content:nth-child(2n) {
        flex-direction: row-reverse;
    }


}

section#showinfo {
    div.article {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    div.temp {
        display: grid;
        grid-template-columns: repeat(2, calc(var(--defaultScale)*615px));
        justify-content: center;

        >div {
            width: calc(var(--defaultScale)*600px);
            height: calc(var(--defaultScale)*348px);
            background-image: url(./images/showbg.png);
            background-size: 100% 100%;

            margin: calc(var(--defaultScale)*10px);

            >div.temp_show_content {
                margin: calc(var(--defaultScale)*28px);
                height: calc(var(--defaultScale)*308px);

                >* {
                    writing-mode: vertical-rl;
                }

                >div.temp_show_content_img {
                    width: calc(var(--defaultScale)*260px);
                    height: calc(var(--defaultScale)*289px);
                    background-color: blue;
                    flex-shrink: 0;

                }

                >div.temp_show_content_text {
                    flex-grow: 1;
                    text-indent: 2em;
                    font-size: calc(var(--defaultScale)*15px);
                    line-height: calc(var(--defaultScale)*27px);
                    margin-top: calc(var(--defaultScale)*10px);
                    margin-bottom: calc(var(--defaultScale)*14px);
                }

                >div.temp_show_content_title {
                    flex-grow: 0;
                    font-size: calc(var(--defaultScale)*19px);
                    font-weight: bolder;
                    width: calc(var(--defaultScale)*50px);
                    margin-right: calc(var(--defaultScale)*18px);
                    margin-top: calc(var(--defaultScale)*25px);

                }

                display: flex;
                flex-direction: row-reverse;

            }

        }
    }
}