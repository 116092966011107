@import "./_inc.scss";
div.contactUs{
   display: flex;
   flex-direction: column;
   margin: calc(var(--defaultScale)*57px) calc(var(--defaultScale)*372px);
    div.contactUs_title{
        align-self: center;
        margin-bottom: calc(var(--defaultScale)*75px);
    }
    p{
        font-size: calc(var(--defaultScale)*20px);
    }
    margin-bottom: calc(var(--defaultScale)*433px);
}