@import '_inc.scss';

section.info{
    div.news{

        --cicleSize:  calc(var(--defaultScale) * 120px);

        div.rectContent{
            position: relative;
        }
        
        hr.col{
            margin: 0 calc(var(--defaultScale) * 284px);
            width: 1px;
            border: none;
            background-color: var(--lineColor);
            position: absolute;
            height: calc(100% + calc(var(--defaultScale) * 72px));
            top: calc(var(--defaultScale) * -36px);
            
        }
        ul{
            margin-top: calc(var(--defaultScale) * 36px);
            list-style-type: none;
            margin-left: calc(var(--defaultScale) * 145px);
            margin-right: calc(var(--defaultScale) * 30px);
        }
        li{
            margin-bottom: calc(var(--defaultScale) * 21px);
        }
        li.year{
            display: flex;
            flex-direction: row;
            align-items: center;
            >hr{
             
                flex-grow: 1;
                border: none;
                background-color: var(--lineColor);
                height: 1px;
            
            }
            div.space{
                width: calc(var(--defaultScale) * 80px);
                
                flex-shrink: 0;
            }
        }
        div.circle{
            width: var(--cicleSize);
            height: var(--cicleSize);
            border-radius: 50%;
            background-color: var(--lineColor);
            color: white;
            text-align: center;
            font-size: calc(var(--defaultScale) * 30px);
            line-height: var(--cicleSize);
            z-index: 1;
        }
        li.content{
            display: flex;
            flex-direction: row;
            align-items: center;
            >span.time{
                flex-shrink: 0;
                width: calc(var(--defaultScale) * 120px);
            }
            *{
                font-size: calc(var(--defaultScale) * 14px);
            }
            >img{
                width: calc(var(--defaultScale) * 237px);
                height: calc(var(--defaultScale) * 149px);
                background-color: blue;
                margin-right: calc(var(--defaultScale) * 40px);
                margin-left: calc(var(--defaultScale) * 40px);
            }
            >span.circle2{
                flex-shrink: 0;
                width: calc(var(--defaultScale) * 30px);
                height: calc(var(--defaultScale) * 30px);
                border-radius: 50%;
                background-color: var(--lineColor);
                background-clip: content-box;
                padding: calc(var(--defaultScale) * 2px);
                border: 2px solid var(--lineColor);
                color: white;
                text-align: center;
                font-size: calc(var(--defaultScale) * 14px);
                line-height: calc(var(--defaultScale) * 30px);
             
                margin-right: calc(var(--defaultScale) * 30px);
            }
            p{
                text-indent: 2em;
                font-size: calc(var(--defaultScale) * 18x);
            }
        }
    }
}