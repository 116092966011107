@import "./inc.scss";

div.search{
  
    display: flex;
    flex-direction: column;
 
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    width: calc(var(--defaultScale)*670px);
    margin: calc(var(--defaultScale)*340px) auto;
    img{
        margin-bottom: calc(var(--defaultScale)*23px);
    }
    >div.search_type{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        >span{
            margin: 0  calc(var(--defaultScale)*16px);
            font-size: calc(var(--defaultScale)*20px);
        }
        margin-bottom: calc(var(--defaultScale)*87px);

    }
    >div.search_input{
        display: flex;
        flex-direction: row;
        height: calc(var(--defaultScale)*37px);
    }
    input{
    

        width: calc(var(--defaultScale)*520px);
    }
    div.search_icon{
        background-color:#992a21 ;
        width: calc(var(--defaultScale)*150px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
    }
}