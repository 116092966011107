@import "_inc.scss";

section.home {

    padding: calc(var(--defaultScale)*84px) 0px calc(var(--defaultScale)*68px) 0px;

    div.content {
        box-sizing: border-box;
        padding: calc(var(--defaultScale)*26px) calc(var(--defaultScale)*42px);
        height: calc(var(--defaultScale)*279px);
        text-align: left;
    }

    div.top {
        display: flex;
        flex-direction: row;
        text-align: center;
        width: calc(var(--defaultScale)*var(--width));
        margin: calc(var(--defaultScale)*15px) auto;


        div.left {
            margin-right: calc(var(--defaultScale)*12px);
            width: calc(var(--defaultScale)*598px);



            div.lefttop {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                height: calc(var(--defaultScale)*540px);
                margin-bottom: calc(var(--defaultScale)*15px);
                background: url(./images/lt.png) no-repeat;
                background-position: right;
                background-size: contain;

                div>p {
                    text-align: left;

                    &.notice {
                        color: #D17E7B;
                        zoom: 0.9;
                    }

                }

                p {

                    font-size: calc(var(--defaultScale) * 18px);
                }

                div.openTime {
                    div.bar {
                        background: url(./images/bar.png) no-repeat;
                        background-position: center;
                    }

                    div.info {
                        text-align: center;
                        font-size: 12px;
                        transform-origin: 0 0;
                        transform: scale(calc(var(--defaultScale)));

                        color: #e8ce98;
                    }

                    background-color: hsla(0, 100%, 20%, 0.785);


                    div.openTimeContent {
                        display: grid;
                        columns: 6;
                        font-size: calc(var(--defaultScale)*28px);
                        grid-template-columns: 1fr 0.8fr 1fr 0.8fr 1fr 1fr;


                    }



                }

                div.commu {
                    grid-row: 1;
                    background-color: hsla(8, 80%, 25%, 0.785);



                }
            }

            div.leftbtm {

                width: 100%;
                height: calc(var(--defaultScale)*317px);
                background: url(./images/home/cornor.png) #2e486a no-repeat left / contain;
            }
        }

        div.right {
            width: calc(var(--defaultScale)*778px);

            div.righttop {


                height: calc(var(--defaultScale)*385px);
                margin-bottom: calc(var(--defaultScale)*15px);
                background: url(./images/2t.png) #cbddc5 no-repeat center / contain;

                position: relative;

                >img {
                    position: absolute;
                    top: -30px;
                    left: 30px;
                    z-index: 1;
                    height: 100%;

                }

                >a {
                    z-index: 2;
                    color: #578078;
                    border: #578078 3px solid;
                    border-radius: 8px;
                    position: absolute;
                    top: calc(var(--defaultScale)*226px);
                    left: calc(var(--defaultScale)*464px);
                    width: calc(var(--defaultScale)*146px);
                    height: calc(var(--defaultScale)*44px);
                    font-size: calc(var(--defaultScale)*25px);
                    vertical-align: middle;
                    text-decoration-line: none;
                }
            }

            div.rightbtm {
                position: relative;
                background: url(./images/museum.png) no-repeat center center / contain;
                height: calc(var(--defaultScale)*472px);

                a {
                    text-decoration: none;
                }

                div.container {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    div.caption {
                        >p {
                            color: #5D4722;
                            margin: 0;
                        }

                        >p.small {
                            transform: scale(0.3);
                            transform-origin: center 0;

                        }

                        margin: auto;
                        margin-top:calc(var(--defaultScale)*277px);
                        text-align: center;
                        font-size: calc(var(--defaultScale)*41px);
                    }
                }

            }
        }



    }

    ul.content {

        font-size: calc(var(--defaultScale)*20px);
        list-style: none;

        li {
            margin-top: calc(var(--defaultScale)*15px);
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            a {
                text-decoration: none;
            }

            >span:nth-child(1) {
                width: calc(var(--defaultScale)*400px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    div.middle {
        margin: calc(var(--defaultScale)*32px) auto;
        height: calc(var(--defaultScale)*328px);
        width: calc(var(--defaultScale)*var(--width));

        display: flex;
        flex-direction: row;
        justify-content: space-between;


        >div.midleft {
            width: calc(var(--defaultScale)*686px);
            height: 100%;
            background: url(./images/home/active.png)#8d6b3a no-repeat right / contain;

            img {
                padding-left: calc(var(--defaultScale)*31px);
            }

        }

        >div.midright {
            width: calc(var(--defaultScale)*686px);
            background: url(./images/home/good.png) no-repeat center / contain;
            height: 100%;
        }

        .content {
            a {
                text-decoration: none;
            }
        }
    }

    div.bottom {
        margin: 0 auto;
        height: calc(var(--defaultScale)*342px);
        width: calc(var(--defaultScale)*var(--width));

        display: flex;
        flex-direction: row;

        div.title {
            display: flex;
            box-sizing: border-box;
            padding: calc(var(--defaultScale)*37px) 0%;
            flex-direction: column;
            width: calc(var(--defaultScale)*95px);
            background: rgb(166, 36, 38);

            height: 100%;
            align-items: center;
            justify-content: space-between;

            div.text {
                writing-mode: vertical-rl;
                text-align: center;


            }

            .sect_icon {
                background-position: center;

            }
        }

        div.container {
            margin-left: calc(var(--defaultScale)*14px);
            width: 100%;

            padding-top: calc(var(--defaultScale)*12px);
            padding-bottom: calc(var(--defaultScale)*12px);
            background-color: white;
            position: relative;

            >div.items {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 100%;
                justify-content: space-between;
                overflow-x: hidden;

                >div {
                    // background: url(./images/home/obj.png) no-repeat center / contain;

                    width: calc(var(--defaultScale)*240px);
                    height: 100%;

                    img {
                        height: 100%;

                    }

                }

            }

            >nav {

                background-color: #00000050;
                height: 100%;
                width: calc(var(--defaultScale)*34px);
                position: absolute;
                background-repeat: no-repeat;
                background-position: center;

                &.prev {
                    left: 0;
                    top: 0;
                    background-image: url(./images/prev.png);
                }

                &.next {
                    right: 0;
                    top: 0;
                    background-image: url(./images/next.png);
                }
            }

        }
    }
}