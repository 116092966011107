﻿%full {
    //铺满
    width: 100%;
    height: 100%;
}

%nopading {
    margin: 0px;
    padding: 0px;
}

%allcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    color: white;
}

      
:root {
    @media screen and (max-width: 1600px) {
        --defaultWidth: 1600;
    }
    @media screen and (min-width: 1600px) {
        --defaultWidth: 1600;
    }
    @media screen and (min-width: 1920px) {
        --defaultWidth: 1920;
    }


    --lineColor : #9C6101;
    --width:1388px;
    --designWidth: 1920;
    --defaultScale: calc(var(--defaultWidth) / var(--designWidth));
}

span.sect_icon {
    background-image: url(./images/sect_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: calc(var(--defaultScale) * 50px);
    height: calc(var(--defaultScale) * 50px);
    display: inline-block;
    vertical-align: middle;
    background-position: left;
    &.right {
        transform: rotateY(180deg);
    }
    &.top{
        background-position:center;
        background-image: url(./images/top.png);
    }
    &.bottom{
        background-position:center;
        background-image: url(./images/bottom.png);
    }
}

section.bg,section>div.bg{
    background: url(./images/home/bg.png);
    margin: auto;
}


article.infos{

    --border : 2px solid var(--lineColor);
    --w1: calc(var(--defaultScale) * 20px);
    --w2: calc(var(--defaultScale) * 16px);
    --w3: calc(var(--defaultScale) * 40px);
    width: 100%;
    *{color: black;}
    background-color: #f9f6f1;

    display: grid;
    grid-template-columns: var(--w1)  var(--w2) var(--w1) var(--w3) 1fr var(--w3)  var(--w1) var(--w2) var(--w1);
    grid-template-rows:  var(--w1) var(--w2) var(--w1) var(--w1) 1fr var(--w1) var(--w1) var(--w2) var(--w1);
    >div.r1{
        border-top: var(--border);
        &.c1,&.c_1{
            border-top: var(--border);
            border-left: var(--border);
            border-right: var(--border);
        }
        &.c2,&.c_2{
            border-top: none;
            background-color: white;
        }
        &.c_3{
            
            border-right: var(--border);
        }
        &.c3{
           
            border-left: var(--border);
        }
        
    }
    >div.r2{
        &.c1,&.c_1,&.c2,&.c_2,&.c3,&.c_3{
            background-color: white;
            border-top: var(--border);
            border-bottom: var(--border);
        }
        &.c3,&.c_3{
            border-top: var(--border);
        }
        &.c{
            border-top: var(--border);
        }
        &.c1{
            
            border-right: var(--border);
        }
        &.c_1{
          
            border-left: var(--border);
        }
        &.c3,&.c_3{
            border-right: var(--border);
            border-left: var(--border);
        }
    }
    >div.r3{
        &.c1,&.c_1{
          
            border-left: var(--border);
            border-right: var(--border);
        }
        &.c2,&.c_2{
            border-bottom: var(--border);
            background-color: white;  
        }
        &.c_3{
            
            border-right: var(--border);
        }
        &.c3{
           
            border-left: var(--border);
        }
    }
    >div.r_3{
        &.c1,&.c_1{
          
            border-left: var(--border);
            border-right: var(--border);
        }
        &.c2,&.c_2{
            border-top: var(--border);
            background-color: white;  
        }
        &.c_3{
            
            border-right: var(--border);
        }
        &.c3{
           
            border-left: var(--border);
        }
    }

    >div.r_2{
        &.c1,&.c_1,&.c2,&.c_2,&.c3,&.c_3{
            background-color: white;
            border-top: var(--border);
            border-bottom: var(--border);
        }
        &.c{
            border-bottom: var(--border);
        }
        &.c3,&.c_3{
            border-top: var(--border);
        }
        &.c1{
            
            border-right: var(--border);
        }
        &.c_1{
          
            border-left: var(--border);
        }
        &.c3,&.c_3{
            border-right: var(--border);
            border-left: var(--border);
        }
       
    }
    >div.r.c{
        grid-column-start: 2;
        grid-column-end: 9;
        grid-row-start: 4;
        grid-row-end: 7;
   
    }
    >div.r.c1,>div.r4.c_1,>div.r_4.c_1{
        border-right: var(--border);
    }
    >div.r.c_1,>div.r4.c1,>div.r_4.c1{
        border-left: var(--border);
    }
 
    >div.r_1{
        border-bottom: var(--border);
        &.c1,&.c_1{
            border-bottom: var(--border);
            border-left: var(--border);
            border-right: var(--border);
        }
        &.c2,&.c_2{
            border-bottom: none;
            background-color: white;
        
           
        }
        &.c_3{
            
            border-right: var(--border);
        }
        &.c3{
           
            border-left: var(--border);
        }
        
    }
    >div.r{
        &.c1{
            border-left: var(--border);
        }
        &.c_1{
            border-right: var(--border);
        }
    }

}

div.btn_title{
    background-image: url(./images/2.png);
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--defaultScale) * 22px);
    width: calc(var(--defaultScale) * 200px);
    height: calc(var(--defaultScale) * 57px);
}
section{
    >nav{
        background-color: #ADADAD;
        div{
            text-align: center;
            height: calc(var(--defaultScale) * 85px);
            font-size: calc(var(--defaultScale) * 29px);
            line-height: calc(var(--defaultScale) * 85px);
            font-weight: bolder;
            a{
                text-decoration-line: none;
                padding: 0 calc(var(--defaultScale) * 100px) ;
            }
        }
    }


    &.headerinfo{
        *{color: black;}
        border: 1px solid #ccc;
        font-size: calc(var(--defaultScale) * 20px);
        
        >nav{
            a.choiced{
                color: #d5717f;
            }
        }
        >div.article{
        
            position: relative;
            margin: auto;
            background-color: white;
            box-sizing: border-box;
            padding-left: calc(var(--defaultScale) * 111px);
            padding-right: calc(var(--defaultScale) * 111px);
            padding-bottom: calc(var(--defaultScale) * 62px);
            div.title{
                height: calc(var(--defaultScale) * 150px);
                display: flex;
                flex-direction: row;
                width: 100%;
                *{color: black; font-weight: 200%;}
                text-align: center;
                width: 100%;
                justify-content: space-between;
              
                align-items: center;
                >img{  
                    height: calc(var(--defaultScale) * 29px);
                    flex-shrink: 1;
                }
                >span{
               
                    width: calc(var(--defaultScale) * 204px);
                    flex-shrink: 0;
                }
               
            }
           
            width: calc(var(--defaultScale) * var(--width));
            div.visitor{
                margin-top: calc(var(--defaultScale) * 11px);
                margin-left: calc(var(--defaultScale) * 89px);
            }
            img{
                width: 100%;
            }
            div.body{
                margin-top: calc(var(--defaultScale) * 85px);
                margin-left: calc(var(--defaultScale) * 76px);
                margin-right: calc(var(--defaultScale) * 76px);
                margin-bottom: calc(var(--defaultScale) * 138px);
                >p{
                    text-indent: 2em;
                    line-height: 1.5em;
                    
                }
               
            }
            div.name{
                margin-bottom: calc(var(--defaultScale) * 150px);
                margin-left: calc(var(--defaultScale) * 876px);
            }
        }
    }
}

div.page{
    a:hover{
        cursor: pointer;
    }
}



div.btn {
    width: calc(var(--defaultScale)*127px);
    height: calc(var(--defaultScale)*36px);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.enabled {
        background: url(./images/services/enable.png) no-repeat center center / 100% 100%;
    }

    &.disabled {
        background: url(./images/services/disable.png) no-repeat center center / 100% 100%;
    }
}